/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, TwoColCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you searching for effective solutions to address your ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), "? At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we understand the impact hearing loss can have on your life, and we are here to help you find the best treatment options available. Our mission is to provide you with personalized care and cutting-edge technologies, including ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "state-of-the-art hearing aids"), ", to enhance your hearing and improve your quality of life. Explore the various treatments for hearing loss and discover how hear.com can assist you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-choose-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-choose-hearcom",
    "aria-label": "why choose hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Choose hear.com?"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Expertise"), ": Our team of highly experienced hearing care professionals has extensive knowledge of the latest advancements in hearing loss treatment. We are dedicated to staying at the forefront of the field and providing you with the highest standard of care."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Personalized Approach"), ": We recognize that every individual's hearing loss is unique, which is why we take a personalized approach to your treatment. Our specialists will conduct a thorough evaluation and work closely with you to understand your specific needs and preferences."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Comprehensive Solutions"), ": At hear.com, we offer a wide range of treatment options tailored to your hearing loss. From hearing aids to assistive listening devices, we have the tools to help you regain your hearing abilities effectively."), "\n"), "\n"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "effective-treatments-for-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#effective-treatments-for-hearing-loss",
    "aria-label": "effective treatments for hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Effective Treatments for Hearing Loss"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing Aids"), ": Our primary focus is on hearing aids, as they are one of the most common and effective solutions for hearing loss. These small electronic devices amplify sounds and improve your ability to hear speech, music, and other sounds in various environments. We offer a wide selection of advanced hearing aids from leading manufacturers to suit your preferences and lifestyle."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Assistive Listening Devices (ALDs)"), ": ALDs are supplementary devices designed to enhance specific listening situations. They can be used in conjunction with hearing aids or as standalone devices. ALDs include options such as ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/hearing-impaired-phone/",
    className: "c-md-a"
  }, "personal amplifiers"), ", captioned telephones, and TV listening systems, providing additional support for challenging listening environments."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cochlear Implants"), ": Cochlear implants are recommended for individuals with severe to profound hearing loss who do not benefit sufficiently from hearing aids. This surgical option bypasses damaged parts of the inner ear and directly stimulates the auditory nerve, enabling improved hearing."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Bone-Anchored Hearing Systems (BAHS)"), ": BAHS are suitable for individuals with conductive or mixed hearing loss. This implantable solution transmits sound vibrations through the bones of the skull to the inner ear, bypassing any damage in the outer or middle ear."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-recommendations",
    "aria-label": "our best recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "exclusive Horizon IX line of hearing aids by hear.com"), ". No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-choose-hearing-aids-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-choose-hearing-aids-from-hearcom",
    "aria-label": "why choose hearing aids from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Choose Hearing Aids from hear.com?"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cutting-Edge Technology"), ": We offer the latest advancements in hearing aid technology, ensuring optimal performance and sound quality."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Wide Range of Styles"), ": Our hearing aids come in various styles, including invisible-in-canal, receiver-in-canal, behind-the-ear, and in-the-ear options, allowing you to choose the one that best suits your preferences and comfort."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Customization"), ": Our hearing aids are customized to fit your unique ear anatomy, providing a comfortable and secure fit for prolonged use."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Enhanced Listening Experience"), ": Experience improved speech understanding, reduced background noise, and seamless connectivity with our advanced hearing aids."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ongoing Support"), ": We are committed to your long-term satisfaction. Our experts provide ongoing support, including adjustments, maintenance, and follow-up care, ensuring that your hearing aids continue to meet your evolving needs."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist",
    "aria-label": "contact a hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a Hearing Specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Regain the joy of hearing with hear.com. Our team of dedicated professionals is ready to guide you through the process of finding the right treatment for your hearing loss. Contact us today to schedule a consultation and take the first step toward better hearing and an improved quality of life."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a Hearing Expert",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
